/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import DashboardLayout from "../components/shared/DashboardLayout";
import { BiExport } from "react-icons/bi";
import StudentAnalyticBarGraph from "../components/graphs/StudentAnalyticBarGraph";
import StudentProgressBarGraph from "../components/graphs/StudentProgressBarGraph";
import useGetStudentsDetail from "../hooks/useGetStudentsDetail";
import { useParams } from "react-router-dom";
import axios from "axios";
import ShowMessage from "../components/shared/ShowMessage";
import { ButtonLoader_2 } from "../components/shared/Loaders";
import { getAccessToken } from "../storage/storage";
import * as XLSX from "xlsx";

function StudentAnalyticsPage() {
  const { id } = useParams();
  const [showPercentage, setShowPercentage] = useState(true);
  const [studentAnalyticsResult, setStudentAnalyticsResult] = useState(null);

  function CalculatePercentage(total, obtain) {
    return Math.round((obtain / total) * 100);
  }

  const exportToExcel = (student_name) => {
    const headers = ["Letter name", "Fall", "Winter", "Spring"];

    const data = studentAnalyticsResult?.student_data?.map((item) => {
      return [
        item.assessment_data_name,
        showPercentage
          ? `${CalculatePercentage(item.total_score, item.Fall)}%`
          : item.Fall,
        showPercentage
          ? `${CalculatePercentage(item.total_score, item.Winter)}%`
          : item.Winter,
        showPercentage
          ? `${CalculatePercentage(item.total_score, item.Spring)}%`
          : item.Spring,
      ];
    });

    const finalData = [headers, ...data];

    // Calculate totals for each column
    const totalRow = ["Total"];
    let totalFall = 0,
      totalWinter = 0,
      totalSpring = 0;

    studentAnalyticsResult?.student_data?.forEach((item) => {
      totalFall +=
        item.Fall instanceof String ? parseFloat(item.Fall) : item.Fall;
      totalWinter +=
        item.Winter instanceof String ? parseFloat(item.Winter) : item.Winter;
      totalSpring +=
        item.Spring instanceof String ? parseFloat(item.Spring) : item.Spring;
    });

    // Calculate the percentage only if total is greater than zero
    const fallPercentage =
      totalFall > 0 ? `${CalculatePercentage(totalFall, totalFall)}%` : "0%";
    const winterPercentage =
      totalWinter > 0
        ? `${CalculatePercentage(totalWinter, totalWinter)}%`
        : "0%";
    const springPercentage =
      totalSpring > 0
        ? `${CalculatePercentage(totalSpring, totalSpring)}%`
        : "0%";

    totalRow.push(
      showPercentage ? fallPercentage : totalFall,
      showPercentage ? winterPercentage : totalWinter,
      showPercentage ? springPercentage : totalSpring
    );

    // Add the total row to the final data
    finalData.push(totalRow);

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(finalData);
    // Calculate max length of data in each column to set column width
    const colWidths = headers.map((header, index) => {
      const maxLength = Math.max(
        header.length,
        ...data.map((row) => (row[index] ? row[index].toString().length : 0))
      );
      return { width: maxLength + 2 };
    });
    // Apply column widths to the worksheet
    ws["!cols"] = colWidths;
    XLSX.utils.book_append_sheet(wb, ws, "Grades");
    XLSX.writeFile(wb, `${student_name}.xlsx`);
  };

  return (
    <DashboardLayout>
      <div className="bg-backgroundColor px-5 py-2 flex flex-col w-full h-auto">
        <MainLayout id={id} exportToExcel={exportToExcel} />
        <StudentTable
          id={id}
          showPercentage={showPercentage}
          setShowPercentage={setShowPercentage}
          studentAnalyticsResult={studentAnalyticsResult}
          setStudentAnalyticsResult={setStudentAnalyticsResult}
          CalculatePercentage={CalculatePercentage}
        />
      </div>
    </DashboardLayout>
  );
}

export default StudentAnalyticsPage;

function MainLayout({ id, exportToExcel }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { GetStudentsDetail, studentsDetail } = useGetStudentsDetail();

  useEffect(() => {
    if (id) {
      GetStudentsDetail(id);
    }
  }, [id]);

  return (
    <>
      <div className=" flex items-center justify-between w-full">
        <h1 className=" font-[700] text-[30px] font-roboto text-[#263238] ">
          Student report
        </h1>

        <div className="flex items-center gap-3">
          {/* <div className="custom-select w-[150px] h-[50px]">
            <select>
              <option value="" disabled selected>
                Select Class
              </option>
              <option value="option-1">Option 1</option>
              <option value="option-1">Option 2</option>
              <option value="option-1">Option 3</option>
            </select>
          </div>
          <div className="custom-select w-[150px] h-[50px]">
            <select>
              <option value="" disabled selected>
                Select Grade
              </option>
              <option value="option-1">Option 1</option>
              <option value="option-1">Option 2</option>
              <option value="option-1">Option 3</option>
            </select>
          </div>
          <div className="custom-select w-[150px] h-[50px]">
            <select>
              <option value="" disabled selected>
                Select Student
              </option>
              <option value="option-1">Option 1</option>
              <option value="option-1">Option 2</option>
              <option value="option-1">Option 3</option>
            </select>
          </div>
          <input
            type="date"
            className="outline-none border border-[#DCDCDC] rounded-[10px] w-auto h-[50px] bg-white cursor-pointer px-2"
          /> */}
          <button
            className="h-[45px] flex items-center border bg-[#017663] border-[#017663] rounded w-[100px] justify-center gap-2 cursor-pointer text-white"
            onClick={() => exportToExcel(studentsDetail?.student_data?.name)}
          >
            <BiExport className="text-[18px]" />
            <p className="2xl:font-[500] 2xl:text-[16px] lg:font-[300] lg:text-[14px]  font-roboto">
              Export
            </p>
          </button>
        </div>
      </div>
      <div className="flex gap-4 mt-8">
        {studentsDetail?.student_data?.profile_picture ? (
          <img
            src={`${BASE_URL}${studentsDetail?.student_data?.profile_picture}`}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        ) : (
          <img
            src={"/avatar_dummy.png"}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        )}

        <div>
          <h1 className="2xl:text-[25px] lg:text-[20px] font-[600] font-roboto text-[#1C1D1E] ">
            {studentsDetail?.student_data?.name}
          </h1>
          <p className="2xl:text-[18px] lg:text-[15px] 2xl:font-[400] font-roboto text-[#7A7A7A]">
            {studentsDetail?.student_data?.student_id}
          </p>
        </div>
      </div>
    </>
  );
}

function StudentTable({
  id,
  showPercentage,
  setShowPercentage,
  studentAnalyticsResult,
  setStudentAnalyticsResult,
  CalculatePercentage,
}) {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  // get student Analytics Result
  const GetStudentAnalyticsResult = async () => {
    await axios
      .get(`${BASE_URL}/api/results?student_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setStudentAnalyticsResult(response.data);
        setLoading(false);
        if (response.data.student_data.length === 0) {
          setMessage("Record not found");
        } else {
          setMessage(null);
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Oops! Something went wrong");
      });
  };
  useEffect(() => {
    GetStudentAnalyticsResult();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center mt-6">
        <h1 className="lg:font-[500] lg:text-[22px] 2xl:font-[700] 2xl:text-[26px]  font-roboto text-[#1C1D1E]">
          Student VS Grade average
        </h1>
        <div className="flex gap-2">
          <div className="flex gap-1">
            <div className="w-[15px] h-[15px] bg-[#017663]"></div>
            <p className="lg:font-[300] lg:text-[10px] 2xl:font-[400] 2xl:text-[12px]  font-roboto text-[#7A7A7A]">
              Benchmark
            </p>
          </div>
          <div className="flex gap-1">
            <div className="w-[15px] h-[15px] bg-[#FFC121]"></div>
            <p className="lg:font-[300] lg:text-[10px] 2xl:font-[400] 2xl:text-[12px]  font-roboto text-[#7A7A7A]">
              Strategic
            </p>
          </div>
          <div className="flex gap-1">
            <div className="w-[15px] h-[15px] bg-[#AD2126]"></div>
            <p className="lg:font-[300] lg:text-[10px] 2xl:font-[400] 2xl:text-[12px]  font-roboto text-[#7A7A7A]">
              Intensive
            </p>
          </div>
        </div>
        <div className="flex gap-5 items-center">
          <h1 className="text-[#1C1D1E] text-[15px] font-[600] font-roboto">
            Show percentage (%)
          </h1>
          <label className="switch">
            <input
              type="checkbox"
              checked={showPercentage}
              onChange={() => setShowPercentage(!showPercentage)}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </div>
      {loading && (
        <div className="h-[400px] flex justify-center items-center bg-white border border-gray-300 rounded-[10px] mt-2 w-full">
          <ButtonLoader_2 />
        </div>
      )}
      {message && !loading ? (
        <div className="h-[400px] flex justify-center items-center bg-white border border-gray-300 rounded-[10px] mt-2 w-full">
          <ShowMessage message={message} />
        </div>
      ) : (
        <div className="h-auto p-4 bg-white border border-gray-300 rounded-[10px] mt-2 w-full">
          <div className="flex w-full">
            <div className="w-[50%]">
              <h1 className="lg:font-[500] lg:text-[22px] 2xl:font-[700] 2xl:text-[26px] text-[#1C1D1E] font-roboto">
                Student
              </h1>
              <div className="border border-[#DCDCDC]">
                <table className=" text-sm text-left border border-[#DCDCDC] w-full text-[#1C1D1E] font-[600] font-roboto text-[12px]">
                  <thead className="sticky z-[1] top-0 bg-[#F0F0F5]">
                    <tr className="w-full">
                      {["Letter name", "Fall", "Winter", "Spring"].map(
                        (item, index) => (
                          <th scope="col" className="py-3 px-3" key={index}>
                            {item}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {studentAnalyticsResult?.student_data?.map(
                      (item, index) => (
                        <tr key={index} className="bg-white">
                          <td className="py-3 px-3 text-[#7A7A7A]">
                            {item?.assessment_data_name}
                          </td>
                          <td
                            className={`py-3 px-3 ${
                              CalculatePercentage(
                                item.total_score,
                                item?.Fall
                              ) < 78
                                ? "text-[#AD2126]"
                                : CalculatePercentage(
                                    item.total_score,
                                    item?.Fall
                                  ) < 95
                                ? "text-[#FFC121]"
                                : "text-[#017663]"
                            }`}
                          >
                            {showPercentage ? (
                              <>
                                {CalculatePercentage(
                                  item.total_score,
                                  item?.Fall
                                )}
                                %
                              </>
                            ) : (
                              <>{item?.Fall}</>
                            )}
                          </td>
                          <td
                            className={`py-3 px-3 ${
                              CalculatePercentage(
                                item.total_score,
                                item?.Winter
                              ) < 78
                                ? "text-[#AD2126]"
                                : CalculatePercentage(
                                    item.total_score,
                                    item?.Winter
                                  ) < 95
                                ? "text-[#FFC121]"
                                : "text-[#017663]"
                            }`}
                          >
                            {showPercentage ? (
                              <>
                                {CalculatePercentage(
                                  item.total_score,
                                  item?.Winter
                                )}
                                %
                              </>
                            ) : (
                              <>{item?.Winter}</>
                            )}
                          </td>
                          <td
                            className={`py-3 px-3 ${
                              CalculatePercentage(
                                item.total_score,
                                item?.Spring
                              ) < 78
                                ? "text-[#AD2126]"
                                : CalculatePercentage(
                                    item.total_score,
                                    item?.Spring
                                  ) < 95
                                ? "text-[#FFC121]"
                                : "text-[#017663]"
                            }`}
                          >
                            {showPercentage ? (
                              <>
                                {CalculatePercentage(
                                  item.total_score,
                                  item?.Spring
                                )}
                                %
                              </>
                            ) : (
                              <>{item?.Spring}</>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                  <thead className="sticky z-[1] top-0 bg-[#F0F0F5]">
                    <tr className="w-full">
                      <th scope="col" className="py-3 px-3">
                        Total
                      </th>
                      <th className="py-3 px-3">
                        {showPercentage ? (
                          <>
                            {CalculatePercentage(
                              studentAnalyticsResult?.student_data?.reduce(
                                (acc, item) => acc + (item?.total_score || 0),
                                0
                              ),
                              studentAnalyticsResult?.student_data?.reduce(
                                (acc, item) => acc + (item?.Fall || 0),
                                0
                              )
                            )}
                            %
                          </>
                        ) : (
                          <>
                            {studentAnalyticsResult?.student_data?.reduce(
                              (acc, item) => acc + (item?.Fall || 0),
                              0
                            )}
                          </>
                        )}
                      </th>
                      <th className="py-3 px-3">
                        {showPercentage ? (
                          <>
                            {CalculatePercentage(
                              studentAnalyticsResult?.student_data?.reduce(
                                (acc, item) => acc + (item?.total_score || 0),
                                0
                              ),
                              studentAnalyticsResult?.student_data?.reduce(
                                (acc, item) => acc + (item?.Winter || 0),
                                0
                              )
                            )}
                            %
                          </>
                        ) : (
                          <>
                            {studentAnalyticsResult?.student_data?.reduce(
                              (acc, item) => acc + (item?.Winter || 0),
                              0
                            )}
                          </>
                        )}
                      </th>
                      <th className="py-3 px-3">
                        {showPercentage ? (
                          <>
                            {CalculatePercentage(
                              studentAnalyticsResult?.student_data?.reduce(
                                (acc, item) => acc + (item?.total_score || 0),
                                0
                              ),
                              studentAnalyticsResult?.student_data?.reduce(
                                (acc, item) => acc + (item?.Spring || 0),
                                0
                              )
                            )}
                            %
                          </>
                        ) : (
                          <>
                            {studentAnalyticsResult?.student_data?.reduce(
                              (acc, item) => acc + (item?.Spring || 0),
                              0
                            )}
                          </>
                        )}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="w-[10%]">
              <table className=" text-sm text-left bg-white w-[100%] mt-9">
                <thead className="sticky z-[1] top-0">
                  <tr className="w-full">
                    {["Max"].map((item, index) => (
                      <th
                        scope="col"
                        className="py-3 px-3 text-center"
                        key={index}
                      >
                        {item}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {studentAnalyticsResult?.student_data?.map((item, index) => (
                    <tr key={index} className="bg-white">
                      <td className="py-3 px-3 text-[#7A7A7A] text-center">
                        {item?.total_score}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <th scope="col" className="py-3 px-3 text-center">
                  {studentAnalyticsResult?.student_data?.reduce(
                    (acc, item) => acc + (item?.total_score || 0),
                    0
                  )}
                </th>
              </table>
            </div>
            <div className="w-[40%]">
              <h1 className=" lg:font-[500] lg:text-[22px] 2xl:font-[700] 2xl:text-[26px] text-[#1C1D1E] font-roboto">
                Grade average
              </h1>
              <div className="border border-[#DCDCDC]">
                <table className=" text-sm text-left border border-[#DCDCDC] w-full text-[#1C1D1E] font-[600] font-roboto text-[12px]">
                  <thead className="sticky z-[1] top-0 bg-[#F0F0F5]">
                    <tr className="w-full">
                      {["Fall", "Winter", "Spring"].map((item, index) => (
                        <th
                          scope="col"
                          className="py-3 px-3 text-center"
                          key={index}
                        >
                          {item}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {studentAnalyticsResult?.other_student?.length > 0 ? (
                    <tbody>
                      {studentAnalyticsResult?.other_student?.map(
                        (item, index) => (
                          <tr key={index} className="bg-white">
                            <td
                              className={`py-3 px-3 text-center ${
                                CalculatePercentage(
                                  item.total_score,
                                  item?.Fall
                                ) < 78
                                  ? "text-[#AD2126]"
                                  : CalculatePercentage(
                                      item.total_score,
                                      item?.Fall
                                    ) < 95
                                  ? "text-[#FFC121]"
                                  : "text-[#017663]"
                              }`}
                            >
                              {showPercentage ? (
                                <>
                                  {CalculatePercentage(
                                    item.total_score,
                                    item?.Fall
                                  )}
                                  %
                                </>
                              ) : (
                                <>{item?.Fall}</>
                              )}
                            </td>
                            <td
                              className={`py-3 px-3 text-center ${
                                CalculatePercentage(
                                  item.total_score,
                                  item?.Winter
                                ) < 78
                                  ? "text-[#AD2126]"
                                  : CalculatePercentage(
                                      item.total_score,
                                      item?.Winter
                                    ) < 95
                                  ? "text-[#FFC121]"
                                  : "text-[#017663]"
                              }`}
                            >
                              {showPercentage ? (
                                <>
                                  {CalculatePercentage(
                                    item.total_score,
                                    item?.Winter
                                  )}
                                  %
                                </>
                              ) : (
                                <>{item?.Winter}</>
                              )}
                            </td>
                            <td
                              className={`py-3 px-3 text-center ${
                                CalculatePercentage(
                                  item.total_score,
                                  item?.Spring
                                ) < 78
                                  ? "text-[#AD2126]"
                                  : CalculatePercentage(
                                      item.total_score,
                                      item?.Spring
                                    ) < 95
                                  ? "text-[#FFC121]"
                                  : "text-[#017663]"
                              }`}
                            >
                              {showPercentage ? (
                                <>
                                  {CalculatePercentage(
                                    item.total_score,
                                    item?.Spring
                                  )}
                                  %
                                </>
                              ) : (
                                <>{item?.Spring}</>
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  ) : (
                    <tbody>
                      {studentAnalyticsResult?.student_data?.map(
                        (item, index) => (
                          <tr key={index} className="bg-white">
                            <td className={`py-3 px-3 text-center`}>0</td>
                            <td className={`py-3 px-3 text-center`}>0</td>
                            <td className={`py-3 px-3 text-center`}>0</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  )}
                  {studentAnalyticsResult?.other_student?.length > 0 ? (
                    <thead className="sticky z-[1] top-0 bg-[#F0F0F5]">
                      <tr className="w-full ">
                        <th scope="col" className="py-3 px-3 text-center">
                          {showPercentage ? (
                            <>
                              {CalculatePercentage(
                                studentAnalyticsResult?.other_student?.reduce(
                                  (acc, item) => acc + (item?.total_score || 0),
                                  0
                                ),
                                studentAnalyticsResult?.other_student?.reduce(
                                  (acc, item) => acc + (item?.Fall || 0),
                                  0
                                )
                              )}
                              %
                            </>
                          ) : (
                            <>
                              {studentAnalyticsResult?.other_student?.reduce(
                                (acc, item) => acc + (item?.Fall || 0),
                                0
                              )}
                            </>
                          )}
                        </th>
                        <th className="text-center">
                          {showPercentage ? (
                            <>
                              {CalculatePercentage(
                                studentAnalyticsResult?.other_student?.reduce(
                                  (acc, item) => acc + (item?.total_score || 0),
                                  0
                                ),
                                studentAnalyticsResult?.other_student?.reduce(
                                  (acc, item) => acc + (item?.Winter || 0),
                                  0
                                )
                              )}
                              %
                            </>
                          ) : (
                            <>
                              {studentAnalyticsResult?.other_student?.reduce(
                                (acc, item) => acc + (item?.Winter || 0),
                                0
                              )}
                            </>
                          )}
                        </th>
                        <th className="text-center">
                          {showPercentage ? (
                            <>
                              {CalculatePercentage(
                                studentAnalyticsResult?.other_student?.reduce(
                                  (acc, item) => acc + (item?.total_score || 0),
                                  0
                                ),
                                studentAnalyticsResult?.other_student?.reduce(
                                  (acc, item) => acc + (item?.Spring || 0),
                                  0
                                )
                              )}
                              %
                            </>
                          ) : (
                            <>
                              {studentAnalyticsResult?.other_student?.reduce(
                                (acc, item) => acc + (item?.Spring || 0),
                                0
                              )}
                            </>
                          )}
                        </th>
                      </tr>
                    </thead>
                  ) : (
                    <thead className="sticky z-[1] top-0 bg-[#F0F0F5]">
                      <tr className="w-full ">
                        <th scope="col" className="py-3 px-3 text-center">
                          0
                        </th>
                        <th scope="col" className="py-3 px-3 text-center">
                          0
                        </th>
                        <th scope="col" className="py-3 px-3 text-center">
                          0
                        </th>
                      </tr>
                    </thead>
                  )}
                </table>
              </div>
            </div>
          </div>
          <h1 className=" lg:font-[500] lg:text-[22px] 2xl:font-[700] 2xl:text-[26px] text-[#1C1D1E] font-roboto mt-4">
            Student Comparison Visuals
          </h1>
          <StudentAnalyticBarGraph
            showPercentage={showPercentage}
            CalculatePercentage={CalculatePercentage}
            studentAnalyticsResult={studentAnalyticsResult}
          />
        </div>
      )}
      {!message && !loading && (
        <div className=" h-auto p-4 bg-white border border-gray-300 rounded-[10px] mt-6  w-[100%]">
          <h1 className=" lg:font-[500] lg:text-[22px] 2xl:font-[700] 2xl:text-[26px] text-[#1C1D1E] font-roboto mt-4">
            Student progress over time
          </h1>
          <StudentProgressBarGraph
            showPercentage={showPercentage}
            CalculatePercentage={CalculatePercentage}
            studentAnalyticsResult={studentAnalyticsResult}
          />
        </div>
      )}
    </>
  );
}
