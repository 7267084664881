import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";

const useAddTeacher = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const { setUpdateResponse } = useContext(GlobalContext);

  const addTeacher = async (payload, handleClose) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/teacher/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })

      .then((response) => {
        handleClose();
        setUpdateResponse(true);
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Teacher successfully added!",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.message || "Oops! Something went wrong",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, addTeacher };
};
export default useAddTeacher;
